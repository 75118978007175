<template>
  <v-app>
    <v-row class="d-flex">
      <v-col cols="12" lg="7" xl="6" class="primary d-flex align-center justify-center">
        <v-container>
          <div class="pa-10">
            <v-row justify="center">
              <v-col cols="10" md="8" xl="5">
                <div class="d-flex align-center align-md-start justify-start flex-column">
                  <img src="@/assets/images/logo/logo-pruvo-white.png" class="logo-pruvo" />
                  <div class="tw-hidden md:tw-block">
                    <h6
                      class="tw-hidden md:tw-block subtitle-1 mt-4 white--text op-5 font-weight-regular"
                    >
                      Olá! Você está a poucos passos de contar com a praticidade
                      da nossa ferramenta. Com a opção
                      de integração do Pruvo com a plataforma Google for
                      Education, facilitamos diariamente a vida de milhares de
                      professores, alunos e instituições.
                    </h6>
                    <v-btn class="mt-4 text-capitalize" x-large outlined color="white">Saiba Mais</v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
      <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
        <v-container>
          <div class="pa-7 pa-sm-12">
            <v-row>
              <v-col cols="12" lg="9" xl="6">
                <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Verifique seu e-mail</h2>
                <v-divider class="my-3"></v-divider>
                <v-form ref="form" lazy-validation>
                  <v-btn
                    color="info"
                    block
                    class="mr-4"
                    submit
                    outlined
                    @click="submit"
                  >Verificar</v-btn>
                </v-form>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  methods: {
    ...mapMutations(['setLoading']),
    async submit () {
      this.$axios.post(
        this.$route.query.url.replace('http://', 'https://')
      ).then(async response => {
        if (response.status === 204) {
          await this.$store.dispatch('alert', { msg: 'E-mail confirmado com sucesso!' })
          this.$router.push('/')
        }
      }).catch(async error => {
        await this.$store.dispatch('alert', { color: 'red', msg: error })
      })
    }
  }
}
</script>
<style scoped>
.logo-pruvo {
  max-width: 200px;
}
.button-google {
  height: 50px !important;
  padding: 1px 1px !important;
  width: 100% !important;
}
.icon-google {
  position: absolute;
  left: 0;
  width: auto;
  height: 48px;
  margin-right: 24px;
  background-color: #fff;
  padding: 12px 12px !important;
  border-radius: 3px;
}
.text-google {
  font-size: 16px;
  margin-left: 22px;
}
</style>
